// Keeping a utils folder around helps with different pieces of reusable logic.
// This file helps parse parameters from URLs

export interface UrlParameters {
  [key: string]: string;
}

export class UrlUtils {
  static parametersFromUrl = (): UrlParameters => {
    // sourced from https://stackoverflow.com/questions/8648892/how-to-convert-url-parameters-to-a-javascript-object
    if (window.location.search.length < 1) {
      return {};
    }

    const search = window.location.search.substring(1);
    return JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
  }

  static getPage = (): string => {
    if (!window.location.pathname) {
      return "";
    }

    let pageLocation = window.location.href[window.location.origin.length] + window.location.href.substring(window.location.origin.length+2)

    return pageLocation;
  }

  static isActive = (page: string): boolean => {
    return page === UrlUtils.getPage();
  }
}
