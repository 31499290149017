import { Reducer } from "redux";
import { ListItem } from "./list-types";
import Sdk from "./local-sdk";
import get from "lodash/get";

export type ListPageState = {
  items: ListItem[];
  itemsLoading: boolean;
  itemsError?: string;
  createLoading: boolean;
  createError?: string;
  deleteLoading: boolean;
  modifyLoading: boolean;
  modifyError?: string;
};

export const initialState: ListPageState = {
  items: [],
  itemsLoading: false,
  createLoading: false,
  deleteLoading: false,
  modifyLoading: false,
};

// action types
export type ListPageAction = CreateItem | ListItems | DeleteItem | ModifyItem | ClearCreateError | ClearModifyError;


interface CreateItem {
  type: CREATE_ITEM;
  payload: Promise<void | TypeError>;
}

interface ListItems {
  type: LIST_ITEMS;
  payload: Promise<ListItem[] | TypeError>;
}

interface DeleteItem {
  type: DELETE_ITEM;
  payload: ListItem; // Item name
}

interface ModifyItem {
  type: MODIFY_ITEM;
  payload: undefined;
}

interface ClearCreateError {
  type: CLEAR_CREATE_ERROR;
  payload: any;
}

interface ClearModifyError {
  type: CLEAR_MODIFY_ERROR;
  payload: any;
}

//constants
type CREATE_ITEM = `list_CREATE_ITEM`;
const CREATE_ITEM: CREATE_ITEM = `list_CREATE_ITEM`;
type LIST_ITEMS = `list_LIST_ITEMS`;
const LIST_ITEMS: LIST_ITEMS = `list_LIST_ITEMS`;
type DELETE_ITEM = `list_DELETE_ITEM`;
const DELETE_ITEM: DELETE_ITEM = `list_DELETE_ITEM`;
type MODIFY_ITEM = `list_MODIFY_ITEM`;
const MODIFY_ITEM: MODIFY_ITEM = `list_MODIFY_ITEM`;
type CLEAR_CREATE_ERROR = `list_CLEAR_CREATE_ERROR`;
const CLEAR_CREATE_ERROR: CLEAR_CREATE_ERROR = `list_CLEAR_CREATE_ERROR`;
type CLEAR_MODIFY_ERROR = `list_CLEAR_MODIFY_ERROR`;
const CLEAR_MODIFY_ERROR: CLEAR_MODIFY_ERROR = `list_CLEAR_MODIFY_ERROR`;

// actions
// export const createItem = (item: ListItem): CreateItem => ({
//   type: CREATE_ITEM,
//   payload: Sdk.createItem(item),
// });

// export const listItems = (): ListItems => ({
//   type: LIST_ITEMS,
//   payload: Sdk.listItems(),
// });

export const clearCreateError = () => ({
  type: CLEAR_CREATE_ERROR,
  payload: undefined,
});

export const deleteItem = (item: ListItem) => ({
  type: DELETE_ITEM,
  payload: Sdk.deleteItem(item.id || ""),
});

// export const modifyItem = (item: ListItem) => ({
//   type: MODIFY_ITEM,
//   payload: Sdk.modifyItem(item),
// });

export const clearModifyError = () => ({
  type: CLEAR_MODIFY_ERROR,
  payload: undefined,
});

// reducer
export const reducer: Reducer<ListPageState, ListPageAction> =
(state: ListPageState = initialState, action: ListPageAction) => {
  switch (action.type) {
    case `${CREATE_ITEM}_PENDING`:
      return {
        ...state,
        createLoading: true,
        createError: undefined,
      };
    case `${CREATE_ITEM}_FULFILLED`:
      return {
        ...state,
        createLoading: false,
      };
    case `${CREATE_ITEM}_REJECTED`:
      return {
        ...state,
        createLoading: false,
        createError: get(action, "payload.message"),
      };
    case CLEAR_CREATE_ERROR:
      return {
        ...state,
        createError: undefined,
      };
    case `${MODIFY_ITEM}_PENDING`:
      return {
        ...state,
        modifyLoading: true,
        modifyError: undefined,
      };
    case `${MODIFY_ITEM}_FULFILLED`:
      return {
        ...state,
        modifyLoading: false,
      };
    case `${MODIFY_ITEM}_REJECTED`:
      return {
        ...state,
        modifyLoading: false,
        modifyError: get(action, "payload.message"),
      };
    case CLEAR_MODIFY_ERROR:
      return {
        ...state,
        modifyError: undefined,
      };
    case `${LIST_ITEMS}_PENDING`: 
      return {
        ...state,
        items: [],
        itemsLoading: true,
        itemsError: undefined,
      }
    case `${LIST_ITEMS}_FULFILLED`:
      return {
        ...state,
        items: action.payload as ListItem[],
        itemsLoading: false,
      };
    case `${LIST_ITEMS}_REJECTED`:
      return {
        ...state,
        itemsError: get(action, "payload.message"),
        items: [],
        itemsLoading: false,
      };
    case `${DELETE_ITEM}_PENDING`:
      return {
        ...state,
        deleteLoading: true,
      };
    case `${DELETE_ITEM}_FULFILLED`:
      return {
        ...state,
        deleteLoading: false,
      };
    case `${DELETE_ITEM}_REJECTED`:
      return {
        ...state,
        deleteLoading: false,
      };
    case MODIFY_ITEM:
      return {
        ...state,
      };
    default:
      return state;
  }
};


