import { ListItem } from "./list-types";
import { API_CONFIG } from "../utils/config";

let userId: string | undefined = undefined;
let jwtToken: string | undefined = undefined;
const endpoint = (suffix: string): string => `${API_CONFIG.URL}/${suffix}`;

const getJwtHeader = () => { return { "Authorization": `${jwtToken}` } };
const getLocalJwtHeader = () => { return { "Authorization": `${jwtToken}`, 'Content-Type': 'application/json' } };
const rejectOnError = async (response: Response) => {
  if (response.status !== 200) {
    const message = await response.text();
    throw new TypeError(message);
  }
  else {
    console.log(response);
  }
};

class LocalSdk {

  static setUserId = (newUserId: string) => userId = newUserId;
  static setJwtToken = (newJwtToken: string) => jwtToken = newJwtToken;

  static deleteItem = async (id: string): Promise<void | TypeError> => {
    if (!userId || !jwtToken) {
      return;
    }

    const host: string = endpoint("delete");
    await fetch(host, {
      method: "DELETE",
      headers: getJwtHeader(),
      mode: "cors",
      body: JSON.stringify({
        "itemId": id,
      }),
    }).then(rejectOnError);
  }


  static getImageFromS3 = async (imageType: string): Promise<any | TypeError> => {
    if (!userId || !jwtToken) {
      return;
    }
    let environment = "prod";
    let host: string = endpoint("getUploadedImageFromS3");
    if (environment === "local") {
      host = "http://localhost:5000/getUploadedImageFromS3";
    }

    let response: any = await fetch(host, {
      method: "POST",
      headers: getJwtHeader(),
      mode: "cors",
      body: JSON.stringify({
        "imageType": imageType,
      }),
    });

    return response.json();
  }


  static makeTitle = async (titleInfo: any): Promise<void | TypeError> => {

    const host: string = endpoint("title");
    const response = await fetch(host, {
      method: "POST",
      headers: getJwtHeader(),
      mode: "cors",
      body: JSON.stringify({
        "fontSize": titleInfo.titleFontSize,
        "fontName": titleInfo.titleFontName,
        "fontColor": titleInfo.titleFontColor,
        "titleText": titleInfo.titleText,
        "imageBrightness": titleInfo.imageBrightness,
        "watermarkFont": titleInfo.watermarkFont,
        "watermarkColor": titleInfo.watermarkColor,
        "watermarkOutlineColor": titleInfo.watermarkOutlineColor,
        "watermarkOutlineSize": titleInfo.watermarkOutlineSize,
        "customWatermark": titleInfo.customWatermark,
        "backgroundImage": titleInfo.backgroundImage,
        "paddingLeft": titleInfo.paddingLeft,
        "paddingRight": titleInfo.paddingRight,
        "imageUrlBlob": titleInfo.imageUrlBlob,
        "outlineSize": titleInfo.outlineSize,
        "outlineColor": titleInfo.outlineColor,
        "alterS3Storage": titleInfo.alterS3Storage,
      }),
    });

    return response.json();

  }

  static makeTextPic = async (textInfo: any): Promise<void | TypeError> => {

    const host: string = endpoint("textpic");
    const response = await fetch(host, {
      method: "POST",
      headers: getJwtHeader(),
      mode: "cors",
      body: JSON.stringify({
        "backgroundColor": textInfo.backgroundColor,
        "fontSize": textInfo.textFontSize,
        "fontName": textInfo.textFontName,
        "text": textInfo.textContent,
        "includePageCount": textInfo.includePageCount,
        "customWatermark": textInfo.customWatermark,
        "watermark": textInfo.watermark,
        "paddingLeft": textInfo.paddingLeft,
        "paddingRight": textInfo.paddingRight,
        "paddingTop": textInfo.paddingTop,
        "paddingBottom": textInfo.paddingBottom,
        "textPicUrlImageBlob": textInfo.textPicUrlImageBlob,
        "textPicFontColor": textInfo.textPicFontColor,
        "watermarkFont": textInfo.watermarkFont,
        "watermarkColor": textInfo.watermarkColor,
        "watermarkOutlineColor": textInfo.watermarkOutlineColor,
        "watermarkOutlineSize": textInfo.watermarkOutlineSize,
        "avoidInstagramSlideCount": textInfo.avoidInstagramSlideCount,
        "alterS3Storage": textInfo.alterS3Storage
      }),
    });

    return response.json();
  }

  static localMakeTitlePic = async (titleInfo: any): Promise<void | TypeError> => {

    const host: string = "http://localhost:5000/generate_cover_image";
    const response = await fetch(host, {
      method: "POST",
      headers: getLocalJwtHeader(),
      mode: "cors",
      body: JSON.stringify({
        "fontSize": titleInfo.titleFontSize,
        "fontName": titleInfo.titleFontName,
        "fontColor": titleInfo.titleFontColor,
        "titleText": titleInfo.titleText,
        "imageBrightness": titleInfo.imageBrightness,
        "customWatermark": titleInfo.customWatermark,
        "watermarkFont": titleInfo.watermarkFont,
        "watermarkColor": titleInfo.watermarkColor,
        "watermarkOutlineColor": titleInfo.watermarkOutlineColor,
        "watermarkOutlineSize": titleInfo.watermarkOutlineSize,
        "backgroundImage": titleInfo.backgroundImage,
        "paddingLeft": titleInfo.paddingLeft,
        "paddingRight": titleInfo.paddingRight,
        "imageUrlBlob": titleInfo.imageUrlBlob,
        "outlineSize": titleInfo.outlineSize,
        "outlineColor": titleInfo.outlineColor,
        "alterS3Storage": titleInfo.alterS3Storage,
      }),
    });

    return response.json();

  }

  static localMakeTextPicSTAGE = async (textInfo: any): Promise<void | TypeError> => {

    const host: string = endpoint("textPicSTAGE");
    const response = await fetch(host, {
      method: "POST",
      headers: getJwtHeader(),
      mode: "cors",
      body: JSON.stringify({
        "backgroundColor": textInfo.backgroundColor,
        "fontSize": textInfo.textFontSize,
        "fontName": textInfo.textFontName,
        "text": textInfo.textContent,
        "includePageCount": textInfo.includePageCount,
        "customWatermark": textInfo.customWatermark,
        "watermark": textInfo.watermark,
        "paddingLeft": textInfo.paddingLeft,
        "paddingRight": textInfo.paddingRight,
        "paddingTop": textInfo.paddingTop,
        "paddingBottom": textInfo.paddingBottom,
        "textPicUrlImageBlob": textInfo.textPicUrlImageBlob,
        "textPicFontColor": textInfo.textPicFontColor,
        "watermarkFont": textInfo.watermarkFont,
        "watermarkColor": textInfo.watermarkColor,
        "watermarkOutlineColor": textInfo.watermarkOutlineColor,
        "watermarkOutlineSize": textInfo.watermarkOutlineSize,
        "avoidInstagramSlideCount": textInfo.avoidInstagramSlideCount,
        "alterS3Storage": textInfo.alterS3Storage
      }),
    });

    return response.json();

  }


  static localMakeTextPic = async (textInfo: any): Promise<void | TypeError> => {

    const host: string = "http://localhost:5000/generate_text_image";
    const response = await fetch(host, {
      method: "POST",
      headers: getLocalJwtHeader(),
      mode: "cors",
      body: JSON.stringify({
        "backgroundColor": textInfo.backgroundColor,
        "fontSize": textInfo.textFontSize,
        "fontName": textInfo.textFontName,
        "text": textInfo.textContent,
        "includePageCount": textInfo.includePageCount,
        "customWatermark": textInfo.customWatermark,
        "watermark": textInfo.watermark,
        "paddingLeft": textInfo.paddingLeft,
        "paddingRight": textInfo.paddingRight,
        "paddingTop": textInfo.paddingTop,
        "paddingBottom": textInfo.paddingBottom,
        "textPicUrlImageBlob": textInfo.textPicUrlImageBlob,
        "textPicFontColor": textInfo.textPicFontColor,
        "watermarkFont": textInfo.watermarkFont,
        "watermarkColor": textInfo.watermarkColor,
        "watermarkOutlineColor": textInfo.watermarkOutlineColor,
        "watermarkOutlineSize": textInfo.watermarkOutlineSize,
        "avoidInstagramSlideCount": textInfo.avoidInstagramSlideCount,
        "alterS3Storage": textInfo.alterS3Storage
      }),
    });

    return response.json();

  }


  static createCheckoutSessionTEST = async (priceId: string) => {
    const host: string = endpoint("stripeTest");
    const response = await fetch(host, {
      method: "POST",
      headers: getJwtHeader(),
      mode: "cors",
      body: JSON.stringify({
        "priceId": priceId,
      }),
    });

    return response.json();
  }


  static stripeCustomerPortalTEST = async (customerId: number): Promise<ListItem[] | TypeError> => {

    const host: string = endpoint("stripeCustomerPortalTest");
    const response = await fetch(host, {
      method: "POST",
      headers: getJwtHeader(),
      mode: "cors",
      body: JSON.stringify({
        "customerId": customerId,
        "returnUrl": "https://texttopic.com/"
      })
    });

    const data = await response.json();
    return data.session;
  };

  static createStripeCheckoutSession = async (priceId: string) => {
    //todo, if local use test checkout -- price_1IDJ3OAl5QfGTeNXjeevGy6w
    // priceId = "price_1JK7WCAl5QfGTeNXETh2OMlC";
    const host: string = endpoint("stripeCheckout");
    const response = await fetch(host, {
      method: "POST",
      headers: getJwtHeader(),
      mode: "cors",
      body: JSON.stringify({
        "priceId": priceId,
      }),
    });

    return response.json();
  }

  static stripeCustomerPortal = async (customerId: string): Promise<ListItem[] | TypeError> => {

    let host: string = endpoint("stripeCustomerPortal");
    if (window.location.href.slice(0, 21) === "http://localhost:3000") {
      host = endpoint("stripeCustomerPortalTest");
    }
    const response = await fetch(host, {
      method: "POST",
      headers: getJwtHeader(),
      mode: "cors",
      body: JSON.stringify({
        "customerId": customerId,
        "returnUrl": "https://texttopic.com/"
      })
    });

    const data = await response.json();
    return data.session;
  };


}

export default LocalSdk;
