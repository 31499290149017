import React from "react";
import ReactDOM from "react-dom";
import CloudWebApp from "./cloud-web-app";

import { Provider } from "react-redux";
import { store } from "./store/cloud-web-app-store";

import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter } from "react-router-dom";

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <CloudWebApp/>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);