
import Auth from "@aws-amplify/auth";
import Amplify from "@aws-amplify/core";

export const UPDATED_CONFIG = true;
export const APP_NAME = "texttopic-tinywebapp";

export const API_CONFIG = {
  REGION: "us-east-1",
  URL: "https://kktduwsmc6.execute-api.us-east-1.amazonaws.com"
};

export const AMPLIFY_CONFIG = {
  REGION: "us-east-1",
  USER_POOL_ID: "us-east-1_6A3juFXUL",
  APP_CLIENT_ID: "7qu32b7aig7fj0ui4f5dbeikuf",
  IDENTITY_POOL_ID: "us-east-1:453c333a-0af2-4dcb-938b-12cd0fb739d4"
};

export const initAuth = () => {
  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: AMPLIFY_CONFIG.REGION,
      userPoolId: AMPLIFY_CONFIG.USER_POOL_ID,
      identityPoolId: AMPLIFY_CONFIG.IDENTITY_POOL_ID,
      userPoolWebClientId: AMPLIFY_CONFIG.APP_CLIENT_ID
    }
  });
  Auth.configure(AMPLIFY_CONFIG);  
}