import React from "react";
import TextToPic from "./modules/home/TextToPicPage";
import StripeSuccess from "./modules/home/stripe-success";
import Contact from "./modules/home/contact";
import PremiumPage from "./modules/home/premium-page";
import NotFound from "./modules/home/not-found-404";
import { Route, Routes } from "react-router-dom";

export const paths = {
  home: "/",
  success: "/payment/success",
  contact: "/contact",
  premium: "/premium"
};

let navigator: any = null;

export const CreatedRoutes: React.FunctionComponent<{}> = () =>
  <Routes>
    <Route path={paths.home} element={<TextToPic {...navigator}/>} />
    <Route path={paths.success} element={<StripeSuccess/>}/>
    <Route path={paths.contact} element={<Contact/>}/>
    <Route path={paths.premium} element={<PremiumPage/>}/>

    <Route path="*" element={<NotFound/>} />
  </Routes>;
