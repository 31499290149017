import React from 'react';
import "./navbuttons.css";

type NavButtonProps = {
  text: string;
  classes: string;
  onClickFunction: () => void;
}

export default class NavButton extends React.Component<NavButtonProps, {}> {

  public clickButtonWrapper(event: any, func: any) {
    event.preventDefault();

    func();
  }

  render() {
    let text = this.props.text;
    let classes = this.props.classes;
    let onClickFunction = this.props.onClickFunction;
    return (
      <button onClick={(e) => {this.clickButtonWrapper(e, onClickFunction)}} className={classes}>
        {text}
      </button>
    )
  }
}
