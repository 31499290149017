import React from "react";
import { text } from "../../i18n/en";

export class NotFound extends React.Component<{}, {}> {
  render() {
    return (
      <div>
        {text.home.notFound}
      </div>
    );
  }
}

export default NotFound;
