import { createStore, combineReducers, applyMiddleware } from "redux";
import { createHashHistory } from "history"; 
import { reducer as listPageReducer, initialState as initialListPageState, ListPageState } from "./list";
import promiseMiddleware from "redux-promise-middleware";
import thunk from "redux-thunk";
import { reducer as loginReducer, initialState as initialLoginState, LoginState } from "./login";
import { connectRouter } from "connected-react-router";

export const history = createHashHistory({ hashType: "noslash" });

// we just need to combine all the reducers and initial states from the different redux files
// it's worth also combining the state interfaces so that typescript can help autofill while you're writing components
const cloudWebAppReducer = combineReducers({
  listPage: listPageReducer,
  login: loginReducer,
  router: connectRouter(history),
});

export type CloudWebAppState = {
  listPage: ListPageState;
  login: LoginState;
};

const cloudWebAppInitialState: CloudWebAppState = {
  listPage: initialListPageState,
  login: initialLoginState,
};

export const store = createStore(cloudWebAppReducer, cloudWebAppInitialState, applyMiddleware(promiseMiddleware, thunk));