import React, { Suspense } from "react";
import ReactGA from 'react-ga';
import { connect } from "react-redux";
import { Container, Row } from "react-bootstrap";
import { RouteProps } from "../../cloud-web-app";
import { Footer } from "../../footer";
import { CloudWebAppState } from "../../store/cloud-web-app-store";
import { withNavigator } from "../../Navigator";
import "./../../css/text-to-pic-page.css"; 

// import leader from '../../images/uploads/ykdp.jpg';

const FailedPaymentBanner = React.lazy(() => import('../../failed-payment-banner'));
const TextPicRow = React.lazy(() => import('./TextPicRow'));
const CoverPicRow = React.lazy(() => import('./CoverPicRow'));

ReactGA.initialize("G-LDBV17Z7CB");

interface ReduxStateProps {
  username?: string;
  hasPaid?: boolean;
  hasPremium?: boolean;
  freeAccessUser?: boolean;
  customerId?: string;
}


export type TextToPicPageProps = ReduxStateProps & RouteProps
class TextToPicPage extends React.Component<TextToPicPageProps, {}> {

  componentDidMount(){
    document.title = 'Text To Pic';
    ReactGA.pageview(window.location.pathname);
  }

  renderPaymentFailedBanner = () => {
    if (!!this.props.username) {
      if (!!this.props.hasPremium) {
        if (!this.props.hasPaid && !this.props.freeAccessUser) {
          return <FailedPaymentBanner/>
        }
      }
    }
  }

  render() {
    return (
      <Container>
     
      <Row>
        {this.renderPaymentFailedBanner()}
      </Row>

       {/* Commenting out leaderboard ad for now */}
        {/* <Row>
          <div className="leader" id="leader">
            <a href="https://smile.amazon.com/gp/product/1735561606/ref=as_li_tl?ie=UTF8&tag=texttopic-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=1735561606&linkId=0d45273d2d2eca89d71d1f916663ebc4">
              <img className="leader-img" src={leader} alt="The Young Knight's Dragon Plight" title="The Young Knight's Dragon Plight"/>
            </a>
          </div>
        </Row> */}
        
        <Suspense fallback = {<div>Loading Text Pic Row...</div>}>
          <TextPicRow hasPremium={this.props.hasPremium} routeProps={this.props}/>
        
        </Suspense>
        <Suspense fallback = {<div>Loading Cover Pic Row...</div>}>
        
          <CoverPicRow hasPremium={this.props.hasPremium} routeProps={this.props}/>
        </Suspense>
       

        <Footer/>
      </Container>
    );
  }


}

const mapReduxStateToProps = (store: CloudWebAppState): ReduxStateProps => ({
  customerId: !!store.login.auth ? store.login.auth.customerId : undefined,
  hasPaid: !!store.login.auth ? store.login.auth.hasPaid === true : undefined,
  freeAccessUser: !!store.login.auth ? store.login.auth.freeAccessUser === true : undefined,
  hasPremium: !!store.login.auth ? store.login.auth.hasPaid === true || store.login.auth.freeAccessUser === true : undefined,
  username: !!store.login.auth ? store.login.auth.username : undefined,
});



export default connect(mapReduxStateToProps)(withNavigator(TextToPicPage));
