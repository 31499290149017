import React from "react";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import premiumIcon from '../../static/icons/blue-logo-large-premium.png';
import checkMark from '../../static/icons/check-mark.png';
import xMark from '../../static/icons/x-mark.png';
import LocalSdk from "../../store/local-sdk";
import "./premium-page.css";
import { CloudWebAppState } from "../../store/cloud-web-app-store";

ReactGA.initialize("G-LDBV17Z7CB");

declare global {
  interface Window { Stripe: any; }
}

interface ReduxStateProps {
  username?: string;
  customerId?: string;
  hasPaid?: boolean;
  freeAccessUser?: boolean;
  hasPremium?: boolean;
}


const stripe = window.Stripe('pk_live_51IAsPZAl5QfGTeNXmMTVnlGU78oDnHhOOt97eM7qvxVeNcdPqs0rXVzXcfnsTXcTUz15dcOLQCOomRhWxsZaI5Jm009nykhoIr', {
  apiVersion: '2020-08-27',
});

// test mode
// const stripe = window.Stripe('pk_test_51IAsPZAl5QfGTeNXc02qadjQrJbehEDYMW6pCYUWU3Tz84hiOgfVNjTkfLxcaZ1ZsMgkVoYTwRc4PEOzsmAzUSDm002L9lAQAZ', {
//   apiVersion: '2020-08-27',
// });


type PremiumPageProps = ReduxStateProps & {
  hasPremium: boolean | undefined
}
class PremiumPage extends React.Component<PremiumPageProps, any>{

  componentDidMount() {
    document.title = 'Premium - Text To Pic';
    ReactGA.pageview(window.location.pathname);
    this.setState({
      priceId: "price_1JK7WCAl5QfGTeNX1wP5I520",
      selectedOption: "Annual"
    });
  }

  onSignupClick = () => {
    LocalSdk.createStripeCheckoutSession(this.state.priceId).then((data) => {
      stripe.redirectToCheckout({
        sessionId: data.sessionId
      })
        .then((response: any) => {
          console.log(response)
        });
    });

  }

  onAnnualClick = () => {
    this.setState({
      priceId: "price_1JK7WCAl5QfGTeNX1wP5I520",
      selectedOption: "Annual"
    })
  }

  onMonthlyClick = () => {
    this.setState({
      priceId: "price_1JK7WCAl5QfGTeNXETh2OMlC",
      selectedOption: "Monthly"
    })
  }

  renderPaymentOptions = () => {
    if (!this.props.hasPremium) {
      return <div>
        <span onClick={this.onAnnualClick} className="radio-button-container">
          <input checked={!!this.state && this.state.selectedOption === "Annual"} onChange={this.onAnnualClick} type="radio" value="annual" name="plan-type" className="radio-button" /> $30/year (save 20%)
        </span>
        <span onClick={this.onMonthlyClick} className="radio-button-container">
          <input checked={!!this.state && this.state.selectedOption === "Monthly"} onChange={this.onMonthlyClick} type="radio" value="monthly" name="plan-type" className="radio-button" /> $3/month
        </span>
      </div>
    }


  }

  renderSignUpButton = () => {
    if (this.props.hasPremium) {
      return <p className="thank-you-message">You already have Premium! Thank you for your&nbsp;support!</p>
    }
    else {
      return <button onClick={this.onSignupClick} className={"sign-up-button"}>Sign Up</button>
    }
  }


  render() {

    return (
      <div className="premium-page-container">
        <div className="premium-page">
          <img className="premium-picture" src={premiumIcon} alt="Premium Icon" />
          <h1 className="premium-header">Sign Up for TextToPic&nbsp;Premium</h1>
          <p className="premium-subheader">Support an indie developer and get exclusive&nbsp;features!</p>
          <div className="premium-plan-box">
            <p className="plans-text">PLANS</p>
            {this.renderPaymentOptions()}
            {this.renderSignUpButton()}

          </div>
        </div>


        <div className="premium-page-feature-container">
          <h3 className="feature-header">Premium users get additional features!</h3>
          <table className="premium-table">
            <thead>
              <tr className="premium-table-feature-row-header">
                <th className="feature-table-feature-header">Features</th>
                <th className="feature-table-free-header">FREE</th>
                <th className="feature-table-premium-header">PREMIUM</th>
              </tr>
            </thead>
            <tbody>
              <tr className="premium-table-feature-row">
                <td className="feature-table-feature-name">Mutliple Font Options</td>
                <td className="feature-table-free-feature"><img className="feature-icon-mark" src={checkMark} alt="Check Mark" /></td>
                <td className="feature-table-premium-feature"><img className="feature-icon-mark" src={checkMark} alt="Check Mark" /></td>
              </tr>
              <tr className="premium-table-feature-row">
                <td className="feature-table-feature-name">Text Styling</td>
                <td className="feature-table-free-feature"><img className="feature-icon-mark" src={checkMark} alt="Check Mark" /></td>
                <td className="feature-table-premium-feature"><img className="feature-icon-mark" src={checkMark} alt="Check Mark" /></td>
              </tr>
              {/* <tr className="premium-table-feature-row">
                <td className="feature-table-feature-name">Max Pictures Downloadable At Once</td>
                <td className="feature-table-free-feature">4</td>
                <td className="feature-table-premium-feature">10</td>
              </tr> */}
              <tr className="premium-table-feature-row">
                <td className="feature-table-feature-name">Unlimited Padding</td>
                <td className="feature-table-free-feature"><img className="feature-icon-mark" src={xMark} alt="X Mark" /></td>
                <td className="feature-table-premium-feature"><img className="feature-icon-mark" src={checkMark} alt="Check Mark" /></td>
              </tr>
              <tr className="premium-table-feature-row">
                <td className="feature-table-feature-name">Customizable Watermark</td>
                <td className="feature-table-free-feature"><img className="feature-icon-mark" src={xMark} alt="X Mark" /></td>
                <td className="feature-table-premium-feature"><img className="feature-icon-mark" src={checkMark} alt="Check Mark" /></td>
              </tr>
              <tr className="premium-table-feature-row">
                <td className="feature-table-feature-name">Save Uploaded Images</td>
                <td className="feature-table-free-feature"><img className="feature-icon-mark" src={xMark} alt="X Mark" /></td>
                <td className="feature-table-premium-feature"><img className="feature-icon-mark" src={checkMark} alt="Check Mark" /></td>
              </tr>
              <tr className="premium-table-feature-row">
                <td className="feature-table-feature-name">Priority Feature Requests</td>
                <td className="feature-table-free-feature"><img className="feature-icon-mark" src={xMark} alt="X Mark" /></td>
                <td className="feature-table-premium-feature"><img className="feature-icon-mark" src={checkMark} alt="Check Mark" /></td>
              </tr>
              <tr className="premium-table-feature-row more-features">
                <td className="feature-table-feature-name" colSpan={3}>More features to come!</td>

              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapReduxStateToProps = (store: CloudWebAppState): PremiumPageProps => ({
  customerId: !!store.login.auth ? store.login.auth.customerId : undefined,
  hasPaid: !!store.login.auth ? store.login.auth.hasPaid === true : undefined,
  freeAccessUser: !!store.login.auth ? store.login.auth.freeAccessUser === true : undefined,
  hasPremium: !!store.login.auth ? store.login.auth.hasPaid === true || store.login.auth.freeAccessUser === true : undefined,
  username: !!store.login.auth ? store.login.auth.username : undefined
});

export default connect(mapReduxStateToProps)(PremiumPage);
