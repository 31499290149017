import React from 'react';
import { Row } from "react-bootstrap";
import "./footer.css";
// import tinystacksIcon from 'https://www.tinystacks.com/_next/static/images/logov2-050801cef86b2c74c3439f81becf9862.svg';



export class Footer extends React.Component {

  render() {
    return (
      <Row className="footer-row">
        <div className="footer">
          <p className="footer-contents">
            Made with <a className="tiny-stacks-text" href="https://tinystacks.com">
              <img className="tinystacks-logo" src="https://www.tinystacks.com/_next/static/images/logov2-050801cef86b2c74c3439f81becf9862.svg" alt="TinyStacks Logo" title="TinyStacks Logo" />
            </a>
          </p>
        </div>
      </Row>
    )
  }
}
