import { Reducer } from "redux";
import Sdk from "./local-sdk";

interface AuthInfo {
  freeAccessUser: boolean;
  hasPaid: boolean;
  customerId: any;
  username: string;
  jwt: string;
  userId: string
}

export type LoginState = {
  auth?: AuthInfo;
  isRegisterModalVisible: boolean;
  isLoginModalVisible: boolean;
  isForgotPasswordModalVisible: boolean;
};

export const initialState: LoginState = {
  isRegisterModalVisible: false,
  isLoginModalVisible: false,
  isForgotPasswordModalVisible: false,
};

// action types
export type LoginAction = Login | Logout | SetRegisterModalVisible | SetLoginModalVisible | SetForgotPasswordModalVisible;

interface Login {
  type: LOGIN;
  payload: AuthInfo;
}

interface Logout {
  type: LOGOUT;
}

interface SetRegisterModalVisible {
  type: SET_REGISTER_MODAL_VISIBLE;
  payload: boolean;
}

interface SetLoginModalVisible {
  type: SET_LOGIN_MODAL_VISIBLE;
  payload: boolean;
}

interface SetForgotPasswordModalVisible {
  type: SET_FORGOT_PASSWORD_MODAL_VISIBLE;
  payload: boolean;
}

// constants
type LOGIN = `login_LOGIN`;
const LOGIN: LOGIN = `login_LOGIN`;
type LOGOUT = `logout_LOGOUT`;
const LOGOUT: LOGOUT = `logout_LOGOUT`;
type SET_REGISTER_MODAL_VISIBLE = `login_SET_REGISTER_MODAL_VISIBLE`;
const SET_REGISTER_MODAL_VISIBLE: SET_REGISTER_MODAL_VISIBLE = `login_SET_REGISTER_MODAL_VISIBLE`;
type SET_LOGIN_MODAL_VISIBLE = `login_SET_LOGIN_MODAL_VISIBLE`;
const SET_LOGIN_MODAL_VISIBLE: SET_LOGIN_MODAL_VISIBLE = `login_SET_LOGIN_MODAL_VISIBLE`;
type SET_FORGOT_PASSWORD_MODAL_VISIBLE = `login_SET_FORGOT_PASSWORD_MODAL_VISIBLE`;
const SET_FORGOT_PASSWORD_MODAL_VISIBLE: SET_FORGOT_PASSWORD_MODAL_VISIBLE = `login_SET_FORGOT_PASSWORD_MODAL_VISIBLE`;


// actions
export const login = (username: string, jwt: string, userId: string, hasPaid: boolean, freeAccessUser: boolean, customerId: string): Login => ({
  type: LOGIN,
  payload: { username, jwt, userId, hasPaid, freeAccessUser, customerId },
});

export const logout = (): Logout => ({
  type: LOGOUT,
});

export const setLoginModalVisible = (visible: boolean): SetLoginModalVisible => ({
  type: SET_LOGIN_MODAL_VISIBLE,
  payload: visible,
});

export const setRegisterModalVisible = (visible: boolean): SetRegisterModalVisible => ({
  type: SET_REGISTER_MODAL_VISIBLE,
  payload: visible,
});

export const setForgotPasswordModalVisible = (visible: boolean): SetForgotPasswordModalVisible => ({
  type: SET_FORGOT_PASSWORD_MODAL_VISIBLE,
  payload: visible,
});

// reducer
export const reducer: Reducer<LoginState, LoginAction> =
(state: LoginState = initialState, action: LoginAction) => {
  switch (action.type) {
    case LOGIN:
      Sdk.setUserId(action.payload.userId);
      Sdk.setJwtToken(action.payload.jwt);
      return {
        ...state,
        auth: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        auth: undefined
      };
    case SET_LOGIN_MODAL_VISIBLE:
      return {
        ...state,
        isLoginModalVisible: action.payload,
      };
    case SET_REGISTER_MODAL_VISIBLE:
      return {
        ...state,
        isRegisterModalVisible: action.payload,
      };
    case SET_FORGOT_PASSWORD_MODAL_VISIBLE:
      return {
        ...state,
        isForgotPasswordModalVisible: action.payload,
      };
    default:
      return state;
  }
};
