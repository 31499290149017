import React, {Suspense} from "react";
import { Navbar, Nav, Form, Modal } from "react-bootstrap";
import NavButton from '../../nav-buttons';
import { text } from "../../i18n/en";
import { login, logout, setLoginModalVisible, setRegisterModalVisible } from "../../store/login";
import { CloudWebAppState } from "../../store/cloud-web-app-store";
import { connect } from "react-redux";
import Auth from "@aws-amplify/auth";
import { initAuth } from "../../utils/config";
import { paths } from "../../Routes";
import { UrlUtils } from "../../utils/page-utils";
import { RouteProps } from "../../cloud-web-app";
import LocalSdk from "../../store/local-sdk";
import userProfileIcon from '../../static/icons/iconmonstr-user-profile.png';
import textToPicIcon from '../../static/icons/blue-logo-no-text-tiny.png';
import "./login-nav.css"


interface ReduxActionProps {
  login: typeof login;
  logout: typeof logout;
  setLoginModalVisible: typeof setLoginModalVisible;
  setRegisterModalVisible: typeof setRegisterModalVisible;
}

interface ReduxStateProps {
  username?: string;
  customerId?: string;
  freeAccessUser?: boolean;
  showLoginModal: boolean;
  showRegisterModal: boolean;
}

type NavbarWithLoginProps = ReduxActionProps & ReduxStateProps & RouteProps;
type NavbarWithLoginState = { 
  isNavExpanded: boolean;
  showProfileModal: boolean;
};

const initialState: NavbarWithLoginState = {
  isNavExpanded: false,
  showProfileModal: false
};

class NavbarWithLogin extends React.Component<NavbarWithLoginProps, NavbarWithLoginState> {
  constructor(props: NavbarWithLoginProps) {
    super(props);
    initAuth();
    this.state = {
      ...initialState
    };
  }

  onLogout = () => {
    this.hideNav();
    Auth.signOut()
      .then(() => {
        this.props.logout();
      })
      .catch(e => console.log(e));
  }

  onShowRegisterModal = () => {
    this.hideNav();
    this.props.setRegisterModalVisible(true);
  }

  onPremiumClick = () => {
    this.props.navigate('/premium');

  }

  onProfileClick = () => {
    if (this.props.customerId != null) {
      LocalSdk.stripeCustomerPortal(this.props.customerId).then((data) => {
        window.location.replace(data.url);
      })
      .catch(() => {
        console.log("Something went wrong");
      });
    }
    else if (this.props.customerId == null && this.props.freeAccessUser) {
      this.setState({
        showProfileModal: true
      });
    }

  }

  onShowLoginModal = () => {
    this.hideNav();
    this.props.setLoginModalVisible(true);
  }

  hideNav = () => {
    this.setState({
      isNavExpanded: false,
    });
  }

  hideProfileModal = () => {
    this.setState({
      showProfileModal: false
    });
  }

  onToggleNav = () => {
    const { isNavExpanded } = this.state;
    this.setState({
      isNavExpanded: !isNavExpanded,
    });
  }

  push = (path: string) => () => {
    this.props.navigate(path);
    this.hideNav();
  }

  
  render() {
    const { username } = this.props;
    const { isNavExpanded } = this.state;
    return (

      <React.Fragment>
        <Modal show={this.state.showProfileModal} onHide={this.hideProfileModal}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header>{text.premium.profileModalHeader}{this.props.username} </Modal.Header>
          <Modal.Body>{text.premium.profileModalMessage}</Modal.Body>
        </Modal>
        <Navbar bg="light" expand="lg" className="fixed-top">
        <div className="navbar-container">
          <Navbar.Brand onClick={this.push(paths.home)} >
          <img src={textToPicIcon} className="brand-icon" alt="Logo"/>
          <p className="brand-text">{text.home.appTitle}</p>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={this.onToggleNav}/>
          <Navbar.Collapse id="basic-navbar-nav" in={isNavExpanded}>
            <Nav className="mr-auto">
              <Nav.Link onClick={this.push(paths.home)} active={UrlUtils.isActive(paths.home)}>
                {text.home.home}
              </Nav.Link>
              <Nav.Link onClick={this.push(paths.premium)} active={UrlUtils.isActive(paths.premium)}>
                Premium
              </Nav.Link>
              <Nav.Link onClick={this.push(paths.contact)} active={UrlUtils.isActive(paths.contact)}>
                Contact Us
              </Nav.Link>
              {/*<Nav.Link onClick={this.push(paths.success)} active={UrlUtils.isActive(paths.success)}>
                Success
              </Nav.Link>*/}
            </Nav>
            <Form inline>
              {username ? this.renderLogoutBar() : this.renderLoginBar()}
            </Form>
          </Navbar.Collapse>
          </div>
        </Navbar>
      </React.Fragment>
    );
  }

  renderLoginBar() {
    return (
      <div className="buttons-when-logged-out">
        <Suspense fallback={<div>Loading buttons...</div>}>
          <NavButton text={text.login.login} onClickFunction={this.onShowLoginModal} classes={"login-btn"}/>
          <NavButton text={text.premium.premium} onClickFunction={this.onPremiumClick} classes={"premium-btn"}/>
        </Suspense>

      </div>
    );
  }

  renderLogoutBar() {
    return (
      <React.Fragment>
        <span className="profile" onClick={this.onProfileClick}>
          <img className="profile-icon" src={userProfileIcon} alt="Profile Icon"/>
          <p className="profile-text">{text.navbar.profile}</p>
        </span>
        <Suspense fallback={<div>Loading logout button...</div>}>
          <NavButton text={text.login.logout} onClickFunction={this.onLogout} classes={"logout-btn"}/>
        </Suspense>
        

      </React.Fragment>
    );
  }
}

const mapReduxStateToProps = (store: CloudWebAppState): ReduxStateProps => ({
  customerId: !!store.login.auth ? store.login.auth.customerId : undefined,
  username: !!store.login.auth ? store.login.auth.username : undefined,
  freeAccessUser: !!store.login.auth ? store.login.auth.freeAccessUser : undefined,
  showLoginModal: store.login.isLoginModalVisible,
  showRegisterModal: store.login.isRegisterModalVisible,
});

const mapReduxActionsToProps: ReduxActionProps = {
  login,
  logout,
  setLoginModalVisible,
  setRegisterModalVisible,
};

export default connect(mapReduxStateToProps, mapReduxActionsToProps)(NavbarWithLogin);
