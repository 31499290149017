import React from "react";
import ReactGA from 'react-ga';
import { Container } from "react-bootstrap";
import emailIcon from '../../static/icons/email-icon.png';
import twitterIcon from '../../static/icons/twitter-icon.png';
import "./contact.css";

ReactGA.initialize("G-LDBV17Z7CB");

class Contact extends React.Component{

  componentDidMount() {
    document.title = 'Contact Us - Text To Pic';
    ReactGA.pageview(window.location.pathname);
  }


  render() {

    return (
      <Container>
        <div className="contact-page">
          <h1 className="contact-header">Contact Us! </h1>
          <p className="contact-message">Interested in requesting features or asking about your profile? Reach out below!</p>

          <a href="https://twitter.com/TextToPicSite" className="link-container">
            <img src={twitterIcon} className="link-icon" alt="Twitter"/>
            <p className="contact-link-twiiter" >
              Twitter
            </p>
          </a>
          <a  href="mailto:contact@texttopic.com" className="link-container">
            <img src={emailIcon} className="link-icon" alt="Email"/>
            <p className="contact-link-email">
              Email
            </p>
          </a>


        </div>
      </Container>
    );
  }


}

export default Contact;
