import React, {Suspense} from "react";
import NavbarWithLogin from "./modules/home/navbar-with-login";
// import LoginModal from "./modules/home/login-modal";
// import ForgotPasswordModal from "./modules/home/forgot-password-modal";
// import RegisterModal from "./modules/home/register-modal";

import { CreatedRoutes } from "./Routes";
import { withNavigator } from "./Navigator";
import { NavigateFunction } from "react-router";

const LoginModal = React.lazy(() => import("./modules/home/login-modal"));
const ForgotPasswordModal = React.lazy(() => import("./modules/home/forgot-password-modal"));
const RegisterModal = React.lazy(() => import("./modules/home/register-modal"));

export type RouteProps = {
  navigator: any,
  location: any,
  navigate: NavigateFunction
}

class CloudWebApp extends React.Component<RouteProps, {}> {
  constructor(props: RouteProps) {
    super(props);
    document.title = "Text To Pic"
  }

  render() {
    return (
      <React.Fragment>
        <NavbarWithLogin {...this.props} />
        <div className="main-app-container">
          <CreatedRoutes {...this.props}/>
        </div>
        <Suspense fallback={<div>Loading divs</div>}>
          <LoginModal />
          <RegisterModal />
          <ForgotPasswordModal />
        </Suspense>
      </React.Fragment>
    );
  }
}

export default withNavigator(CloudWebApp as any);
