// We recommend you keep this file around, and keep all displayed text here.
// Keeping your text here will allow you to localize/internationalize your app fairly easily.

export const text = {
  home: {
    appTitle: `Text To Pic`,
    welcomeHeader: `Hello world!`,
    welcome: `You just launched your first web app! We've tried to keep it light around here, please go through and get acquainted with some of the files in this project.`,
    keyFiles: `Take a look at some of the key files in this project:`,
    notFound: `Not found`,
    home: `Home`,
    hangTight: `Hang tight!`,
    creating: `We're still creating your APIs, so you'll have to refresh this page in a few minutes. You can see the code deploy at`,
    codePipeline: `AWS CodePipeline`,
    link: `(link)`,
  },
  keyFiles: {
    home: `This page:src/modules/home/home.tsx`,
    en: `All the text in this site:src/i18n/en.tsx`,
    redux: `This site's redux store definition:src/store/cloud-web-app-store.ts`,
    config: `Configuration for API and login interactions:src/utils/config.ts`,
  },
  navbar: {
    premium: "Premium",
    profile: "Profile"
  },
  premium: {
    premium: "Premium",
    profileModalHeader: "Logged in as: ",
    profileModalMessage: "Normally, this link would open the billing page, but you have been granted access to a Free Premium Account. If there is a problem, please don't hesitate to reach out!",
    paymentFailed: "Your payment for Premium Access seems to have failed. You will not be able to access the Premium Features until this is fixed."
  },
  login: {
    login: `Login`,
    logout: `Logout`,
    register: `Register`,
    verify: `Verify`,
    email: `Email`,
    password: `Password`,
    checkForVerificationCode: `Check your email for a verification code`,
    loggedInAs: (username: string) => `Logged in as ${username}`,
    blockViewForAuth: `to continue`,
    newPassword: `New password`,
    forgotPassword: `Forgot my password`,
    resetSuccess: `Successfully reset password. Login to continue.`,
    reset: `Reset password`,
  },
  textToPicSuccess: {
    successHeader: `Thank you!`
  },
  textToPicHome: {
    textEditorButtonText: `Clear Textbox`,
    textAreaTitle: `Text Pic Editor`,
    textPreviewTitle: `Text Pic Preview`,
    textPreviewMobileTitle: `Text Pic`,
    textEditorBackgroundColor: `Background Color`,
    textEditorFontSize: `Font Size`,
    textEditorFontName: `Font`,
    textEditorDownloadImages: `Download Pics`,

    titleAreaTitle: `Cover Pic Editor`,
    titlePreviewTitle: `Cover Pic Preview`,
    titlePreviewMobileTitle: `Cover Pic`,
    titleTextAreaDefaultText: `Cover Pic

*Italicized text*

**Bold text**

***Bold and italicized***`,
    textContentDefaultText: `Enter text to create text pics...

If you add enough text, additional images will be created. Up to ten pages.

Click between the images under the picture frame to change which image will sit in the frame. Select the second image to get instructions on how to style text!




To style text, simply add asterisks to either side of whatever words or sentences you'd like styled as shown below:

\\*One asterisk\\*
*Italicized text*

\\*\\*Two asterisks\\*\\*
**Bold text**

\\*\\*\\*Three asterisks\\*\\*\\*
***Bold and italicized***`,
    titleEditorFontSize: `Font Size`,
    titleEditorFontName: `Font`,
    titleEditorDownloadImage: `Download Cover Pic`,
    titleEditorSelectBackground: `Background Image »`
  }
};
