import React from "react";
import { text } from "../../i18n/en";
import { Container, Row } from "react-bootstrap";
import "./stripe-success.css";



class StripeSuccess extends React.Component{


  render() {

    return (
      <Container>
        <Row className="success-page">
          <h1 className="success-header">{text.textToPicSuccess.successHeader} </h1>
          <p className="success-messaging">Thank you for registering for TextToPic Premium! Check your email for instructions on how to log into your new&nbsp;account!</p>

          <a className="link-home" href="/">
            <p className="link-home-text">Return Home</p>
          </a>

        </Row>
      </Container>
    );
  }


}

export default StripeSuccess;
